import React, { useState } from 'react';
import './CadastroHorario.css';
import axios from 'axios';

function ModalFormulario() {
  const [horas, setHoras] = useState({
    descricao: '',
    dia_semana: '',
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setHoras(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    const tableData = document.querySelectorAll('.TableHorario tbody tr');
  
    const payload = {
      descricao: horas.descricao,
      dia_semana: horas.dia_semana,
      entrada1: [],
      saida1: [],
      entrada2: [],
      saida2: [],
      entrada3: [],
      saida3: [],
      tol_extra: [],
      tol_falta: [],
      carga: []
    };
  
    // Função para remover os caracteres indesejados
    const removeInvalidChars = (str) => str.replace(/[^0-9:]/g, '');
  
    // Iterar sobre os dados da tabela e adicionar os valores ao payload
    tableData.forEach((row) => {
      const cells = row.querySelectorAll('td');
      payload.entrada1.push(removeInvalidChars(cells[1].innerText.trim()));
      payload.saida1.push(removeInvalidChars(cells[2].innerText.trim()));
      payload.entrada2.push(removeInvalidChars(cells[3].innerText.trim()));
      payload.saida2.push(removeInvalidChars(cells[4].innerText.trim()));
      payload.entrada3.push(removeInvalidChars(cells[5].innerText.trim()));
      payload.saida3.push(removeInvalidChars(cells[6].innerText.trim()));
      payload.tol_extra.push(removeInvalidChars(cells[7].innerText.trim()));
      payload.tol_falta.push(removeInvalidChars(cells[8].innerText.trim()));
      payload.carga.push(removeInvalidChars(cells[9].innerText.trim()));
    });
  
    try {
      // Enviar os dados para o servidor
      await axios.post('https://pontoweb.kiponto.com:8900/salvar-horas', payload);
      alert('Horas salvas com sucesso!');
    } catch (error) {
      console.error('Erro ao salvar as horas:', error.message);
      alert('Erro ao salvar as horas. Por favor, tente novamente.');
    }
  };
  

  return (
   <div className="FormHorario">
    <div className="formulario">
      <div className="header-formulario">
        <i className="fas fa-user"></i> {/* Ícone de usuário */}
        <span>EMPRESA</span>
      </div>
      <h2>DADOS DE IDENTIFICAÇÃO</h2>
      
      <form className="formulario" onSubmit={handleSubmit}>
          <label htmlFor="descricao"><span>Descrição: </span>
            <input type="text" id="descricao" name="descricao" value={horas.descricao} onChange={handleChange} required />
          </label>

          <label htmlFor="dia_semana"><span>Dia da semana: </span>
            <select id="dia_semana" name="dia_semana" value={horas.dia_semana} onChange={handleChange} required>
              <option value="SEMANAL">SEMANAL</option>
              <option value="CÍCLICA">CÍCLICA</option>
              <option value="MENSAL">MENSAL</option>
            </select>
          </label>

        <table className="TableHorario">
          <thead>
            <tr>
              <th className="th td"></th>
              <th className="th td">Entrada 1</th>
              <th className="th td">Saída 1</th>
              <th className="th td">Entrada 2</th>
              <th className="th td">Saída 2</th>
              <th className="th td">Entrada 3</th>
              <th className="th td">Saída 3</th>
              <th className="th td">Tol. de Extra</th>
              <th className="th td">Tol. de Falta</th>
              <th className="th td">Carga</th>
            </tr>
            </thead>
            <tbody>
                <tr>
                    <td className="th td">Segunda-Feira</td>
                    <td className="th td" contentEditable="true" onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.key == ':'"></td>
                    <td className="th td" contentEditable="true" onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.key == ':'"></td>
                    <td className="th td" contentEditable="true" onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.key == ':'"></td>
                    <td className="th td" contentEditable="true" onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.key == ':'"></td>
                    <td className="th td" contentEditable="true" onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.key == ':'"></td>
                    <td className="th td" contentEditable="true" onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.key == ':'"></td>
                    <td className="th td" contentEditable="true" onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.key == ':'"></td>
                    <td className="th td" contentEditable="true" onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.key == ':'"></td>
                    <td className="th td" contentEditable="true"></td>
                </tr>
                <tr>
                    <td className="th td">Terça-Feira</td>
                    <td className="th td" contentEditable="true" onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.key == ':'"></td>
                    <td className="th td" contentEditable="true" onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.key == ':'"></td>
                    <td className="th td" contentEditable="true" onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.key == ':'"></td>
                    <td className="th td" contentEditable="true" onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.key == ':'"></td>
                    <td className="th td" contentEditable="true" onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.key == ':'"></td>
                    <td className="th td" contentEditable="true" onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.key == ':'"></td>
                    <td className="th td" contentEditable="true" onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.key == ':'"></td>
                    <td className="th td" contentEditable="true" onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.key == ':'"></td>
                    <td className="th td" contentEditable="true" onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.key == ':'"></td>
                </tr>
                <tr>
                    <td className="th td">Quarta-Feira</td>
                    <td className="th td" contentEditable="true" onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.key == ':'"></td>
                    <td className="th td" contentEditable="true" onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.key == ':'"></td>
                    <td className="th td" contentEditable="true" onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.key == ':'"></td>
                    <td className="th td" contentEditable="true" onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.key == ':'"></td>
                    <td className="th td" contentEditable="true" onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.key == ':'"></td>
                    <td className="th td" contentEditable="true" onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.key == ':'"></td>
                    <td className="th td" contentEditable="true" onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.key == ':'"></td>
                    <td className="th td" contentEditable="true" onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.key == ':'"></td>
                    <td className="th td" contentEditable="true" onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.key == ':'"></td>
                </tr>
                <tr>
                    <td className="th td">Quinta-Feira</td>
                    <td className="th td" contentEditable="true" onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.key == ':'"></td>
                    <td className="th td" contentEditable="true" onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.key == ':'"></td>
                    <td className="th td" contentEditable="true" onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.key == ':'"></td>
                    <td className="th td" contentEditable="true" onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.key == ':'"></td>
                    <td className="th td" contentEditable="true" onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.key == ':'"></td>
                    <td className="th td" contentEditable="true" onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.key == ':'"></td>
                    <td className="th td" contentEditable="true" onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.key == ':'"></td>
                    <td className="th td" contentEditable="true" onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.key == ':'"></td>
                    <td className="th td" contentEditable="true" onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.key == ':'"></td>
                </tr>
                <tr>
                    <td className="th td">Sexta-Feira</td>
                    <td className="th td" contentEditable="true" onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.key == ':'"></td>
                    <td className="th td" contentEditable="true" onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.key == ':'"></td>
                    <td className="th td" contentEditable="true" onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.key == ':'"></td>
                    <td className="th td" contentEditable="true" onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.key == ':'"></td>
                    <td className="th td" contentEditable="true" onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.key == ':'"></td>
                    <td className="th td" contentEditable="true" onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.key == ':'"></td>
                    <td className="th td" contentEditable="true" onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.key == ':'"></td>
                    <td className="th td" contentEditable="true" onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.key == ':'"></td>
                    <td className="th td" contentEditable="true" onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.key == ':'"></td>
                </tr>
                <tr>
                    <td className="th td">Sábado</td>
                    <td className="th td" contentEditable="true" onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.key == ':'"></td>
                    <td className="th td" contentEditable="true" onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.key == ':'"></td>
                    <td className="th td" contentEditable="true" onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.key == ':'"></td>
                    <td className="th td" contentEditable="true" onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.key == ':'"></td>
                    <td className="th td" contentEditable="true" onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.key == ':'"></td>
                    <td className="th td" contentEditable="true" onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.key == ':'"></td>
                    <td className="th td" contentEditable="true" onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.key == ':'"></td>
                    <td className="th td" contentEditable="true" onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.key == ':'"></td>
                    <td className="th td" contentEditable="true" onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.key == ':'"></td>
                </tr>
                <tr>
                    <td className="th td">Domingo</td>
                    <td className="th td" contentEditable="true" onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.key == ':'"></td>
                    <td className="th td" contentEditable="true" onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.key == ':'"></td>
                    <td className="th td" contentEditable="true" onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.key == ':'"></td>
                    <td className="th td" contentEditable="true" onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.key == ':'"></td>
                    <td className="th td" contentEditable="true" onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.key == ':'"></td>
                    <td className="th td" contentEditable="true" onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.key == ':'"></td>
                    <td className="th td" contentEditable="true" onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.key == ':'"></td>
                    <td className="th td" contentEditable="true" onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.key == ':'"></td>
                    <td className="th td" contentEditable="true" onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.key == ':'"></td>
                </tr>
            </tbody>
        </table>

        <div className="btns">
          <button className="button-salvar" type="submit">Salvar</button>
          <button className="button-cancelar" type="reset">Cancelar</button>
        </div>
      </form>
    </div>
    </div>
  );
}

export default ModalFormulario;