import React, { useState } from 'react';
import axios from 'axios';
import './FuncaoInit.css';

function Cadastroferiado() {
  const [formData, setFormData] = useState({
    feriado: '',
    descricao: ''
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post('https://pontoweb.kiponto.com:8900/cadastro-feriado', {
        data: formData.feriado,
        descricao: formData.descricao,
        ponto_facultativo: formData.pontoFacultativo
      });
      alert('Feriado cadastrado com sucesso!');
      setFormData({ feriado: '', descricao: '', pontoFacultativo: false });
    } catch (error) {
      alert('Erro ao cadastrar o feriado.');
      console.error('Erro:', error);
    }
  };
  

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  return (
    <div className="BodEmpresas">
      <div className="formulario-funcao">
        <div className="header-formulario-Empresa">
          <span>FERIADOS</span>
        </div>
        <form className="formulario-Empresa" onSubmit={handleSubmit} encType="multipart/form-data">
          <label htmlFor="feriado">
            <span>Data: </span>
            <input type="date" id="feriado" name="feriado" value={formData.feriado} onChange={handleChange} required />
          </label>
          <label htmlFor="descricao">
            <span>Descriçao: </span>
            <textarea id="descricao" name="descricao" value={formData.descricao} onChange={handleChange} className="textarea-descricao" required />
          </label>
          <label>
          <span>Ponto Facultativo: </span>
          <input
            type="checkbox"
            name="pontoFacultativo"
            checked={formData.pontoFacultativo}
            onChange={(e) => setFormData(prevState => ({ ...prevState, pontoFacultativo: e.target.checked }))}
          />
        </label>
          <div className="btn-funcao">
            <button className="button-salvar" type="submit">Salvar</button>
            <button className="button-cancelar" type="reset" onClick={() => setFormData({ feriado: '', descricao: '' })}>Cancelar</button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Cadastroferiado;
