import React, { useState } from 'react';
import axios from 'axios';
import './FuncaoInit.css';

function Cadastroescolaridade() {
  const [escolaridade, setEscolaridade] = useState('');
  const [descricao, setDescricao] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post('https://pontoweb.kiponto.com:8900/cadastro-escolaridade', { descricao, escolaridade });
      alert('Escolaridade cadastrada com sucesso!');
      setEscolaridade('');
      setDescricao('');
    } catch (error) {
      alert('Erro ao cadastrar a escolaridade.');
      console.error('Erro:', error);
    }
  };

  return (
    <div className="BodEmpresas">
      <div className="formulario-funcao">
        <div className="header-formulario-Empresa">
          <span>Escolaridade</span>
        </div>
        <form className="formulario-Empresa" onSubmit={handleSubmit} encType="multipart/form-data">
          <label htmlFor="descricao">
            <span>Descrição: </span>
            <input type="text" id="descricao" name="descricao" value={descricao} onChange={(e) => setDescricao(e.target.value)} required />
          </label>
          <label htmlFor="escolaridade">
            <span>Escolaridade: </span>
            <input type="text" id="escolaridade" name="escolaridade" value={escolaridade} onChange={(e) => setEscolaridade(e.target.value)} required />
          </label>
          <div className="btn-funcao">
            <button className="button-salvar" type="submit">Salvar</button>
            <button className="button-cancelar" type="reset">Cancelar</button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Cadastroescolaridade;