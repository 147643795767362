import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './CadastroUsuario.css'; // Importe o arquivo CSS com estilos

const CadastroUsuario = () => {
  const [userData, setUserData] = useState({
    nome: '', cpf: '', folha: '', pasep: '', identificador: '', dataAlteracao: '', empresa: '', horario: '', funcao: '', departamento: '', estrutura: '', dataAdmissao: '', dataDemissao: '', motivoDemissao: '', cptps: '', endereco: '', bairro: '', cidade: '', estado: '', cep: '', telefone: '', email: '', rg: '', expedicao: '', orgaoExpeditor: '', uf: '', pai: '', mae: '', nascimento: '', genero: '', naturalidade: '', escolaridade: ''
  });
  const [activeTab, setActiveTab] = useState(0);
  const [message, setMessage] = useState('');
  const [empresas, setEmpresas] = useState([]);
  const [horarios, setHorarios] = useState([]);
  const [departamentos, setDepartamentos] = useState([]);
  const [estruturas, setEstruturas] = useState([]);
  const [escolaridades, setEscolaridades] = useState([]);
  const [funcoes, setFuncoes] = useState([]);
  

  useEffect(() => {
    fetchEmpresas();
    fetchHorarios();
    fetchDepartamentos();
    fetchEstruturas();
    fetchEscolaridades();
    fetchFuncoes();
  }, []);

  const fetchEmpresas = async () => {
    try {
      const response = await axios.get('https://pontoweb.kiponto.com:8900/dados-empresas');
      setEmpresas(response.data);
    } catch (error) {
      console.error('Erro ao buscar empresas:', error);
    }
  };

  const fetchFuncoes = async () => {
      try {
        const response = await axios.get('https://pontoweb.kiponto.com:8900/descricao');
        setFuncoes(response.data);
      } catch (error) {
        console.error('Erro ao carregar funções:', error);
      }
  };

  const fetchHorarios = async () => {
    try {
      const response = await axios.get('https://pontoweb.kiponto.com:8900/horarios');
      setHorarios(response.data);
    } catch (error) {
      console.error('Erro ao buscar horários:', error);
    }
  };

  const fetchDepartamentos = async () => {
    try {
      const response = await axios.get('https://pontoweb.kiponto.com:8900/departamentos');
      setDepartamentos(response.data);
    } catch (error) {
      console.error('Erro ao buscar departamentos:', error);
    }
  };

  const fetchEstruturas = async () => {
    try {
      const response = await axios.get('https://pontoweb.kiponto.com:8900/dados-estruturas');
      setEstruturas(response.data);
    } catch (error) {
      console.error('Erro ao buscar estruturas:', error);
    }
  };

  const fetchEscolaridades = async () => {
    try {
      const response = await axios.get('https://pontoweb.kiponto.com:8900/escolaridades');
      setEscolaridades(response.data);
    } catch (error) {
      console.error('Erro ao buscar escolaridades:', error);
    }
  };

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  const formatCPF = (cpf) => {
    return cpf
      .replace(/\D/g, '') // Substitui qualquer caracter que não seja número por nada
      .replace(/(\d{3})(\d)/, '$1.$2') // Coloca ponto após o terceiro dígito
      .replace(/(\d{3})(\d)/, '$1.$2') // Coloca ponto após o sexto dígito
      .replace(/(\d{3})(\d)/, '$1-$2') // Coloca um hífen após o nono dígito
      .replace(/(-\d{2})\d+?$/, '$1'); // Permite apenas 11 dígitos para o CPF, descartando o resto
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let formattedValue = value;

    if (name === 'cpf') {
      formattedValue = formatCPF(value);
    }

    setUserData(prevState => ({
      ...prevState,
      [name]: formattedValue
    }));
    setMessage(''); // Limpar a mensagem quando o usuário começa a digitar novamente
  };

  const handleCEPBlur = async (e) => {
    const cep = e.target.value.replace(/\D/g, ''); // Remover caracteres não numéricos
    if (cep.length !== 8) {
      return;
    }
    try {
      const response = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);
      const { logradouro, bairro, localidade, uf } = response.data;
      setUserData(prevState => ({
        ...prevState,
        endereco: logradouro || '',
        bairro: bairro || '',
        cidade: localidade || '',
        estado: uf || ''
      }));
    } catch (error) {
      console.error('Erro ao obter o endereço:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const requiredFields = ['nome', 'cpf', 'folha', 'identificador', 'empresa', 'horario', 'funcao', 'departamento', 'dataAdmissao'];
    const isValid = requiredFields.every(field => userData[field].trim() !== '');
    
    if (!isValid) {
      setMessage('Por favor, preencha todos os campos obrigatórios.');
      return;
    }    

    try {
      await axios.post('https://pontoweb.kiponto.com:8900/cadastro-usuario', userData);
      setMessage('Usuário cadastrado com sucesso!');
    } catch (error) {
      setMessage('Erro ao cadastrar usuário.');
      console.error(error);
    }
  };

  return (
    <div className="app-body">
      <div className="formulario">
        <div className="header-formulario">
          <i className="fas fa-user"></i>
          <span>FUNCIONÁRIO</span>
        </div>
        <h2>DADOS DE IDENTIFICAÇÃO</h2>
        <div className="imagem-quadrado">
          <label htmlFor="fotoInput">
            <img src="image.png" alt="" style={{ width: '150px', margin: '12px 0 0 20px', cursor: 'pointer' }} />
          </label>
          <input type="file" id="fotoInput" accept="image/*" style={{ display: 'none' }} />
        </div>
        <form action=" " style={{ width: '80%', marginTop: '-12%' }} onSubmit={handleSubmit}>
          <label htmlFor="nome" className={userData.nome.trim() === '' ? 'error' : ''}><span>Nome: </span><input type="text" id="nome" name="nome" value={userData.nome} onChange={handleChange} placeholder="Nome Completo" required /></label>
          <label htmlFor="cpf" className={userData.cpf.trim() === '' ? 'error' : ''}><span>CPF: </span><input type="text" id="cpf" name="cpf" value={userData.cpf} onChange={handleChange} placeholder="000.000.000-00" required /></label>
          <label htmlFor="folha" className={userData.folha.trim() === '' ? 'error' : ''}><span>Nº Folha: </span><input type="number" id="folha" name="folha" value={userData.folha} onChange={handleChange} /></label>
          <label htmlFor="pasep"><span>Nº PIS/PASEP:</span><input type="text" id="pasep" name="pasep" value={userData.pasep} onChange={handleChange} /></label>
          <label htmlFor="identificador" className={userData.identificador.trim() === '' ? 'error' : ''}><span>Nº Identificador:</span><input type="text" id="identificador" name="identificador" value={userData.identificador} onChange={handleChange} /></label>
          <label htmlFor="dataAlteracao"><span>Data de Alteração:</span><input type="date" id="dataAlteracao" name="dataAlteracao" value={userData.dataAlteracao} onChange={handleChange} /></label>
        </form>
        <div className="minhaLinha"></div>
        <div className="abas">
          <div className={`aba ${activeTab === 0 ? 'ativo' : ''}`} onClick={() => handleTabClick(0)}>DADOS GENÉRICOS</div>
          <div className={`aba ${activeTab === 1 ? 'ativo' : ''}`} onClick={() => handleTabClick(1)}>DADOS ADICIONAIS</div>
          <div className={`aba ${activeTab === 2 ? 'ativo' : ''}`} onClick={() => handleTabClick(2)}>BIOMETRIAS</div>
          <div className={`aba ${activeTab === 3 ? 'ativo' : ''}`} onClick={() => handleTabClick(3)}>AFASTAMENTO</div>
        </div>
        <div className={`conteudo-aba ${activeTab === 0 ? 'ativo' : ''}`}>
          <form className="formulario" onSubmit={handleSubmit}>

          <label htmlFor="empresa" className={userData.empresa.trim() === '' ? 'error' : ''}>
              <span>Empresa: </span>
              <select id="empresa" name="empresa" value={userData.empresa} onChange={handleChange}>
                <option value="">SELECIONE UMA EMPRESA</option>
                {empresas.map(empresa => (
                  <option key={empresa.cnpj} value={empresa.nome}>{empresa.nome}</option>
                ))}
              </select>
            </label>

            <label htmlFor="horario" className={userData.horario.trim() === '' ? 'error' : ''}>
              <span>Horário: </span>
              <select id="horario" name="horario" value={userData.horario} onChange={handleChange}>
                <option value="">SELECIONE UM HORÁRIO</option>
                {horarios.map((horario) => (
                  <option key={horario.id} value={horario.descricao}>
                    {horario.descricao}
                  </option>
                ))}
              </select>
              <a href="/cadastro-horarios" className="cent-img">
              <img src="./list.png" alt="Descrição da imagem" />
              </a>
            </label>


            <label htmlFor="funcao" className={userData.funcao.trim() === '' ? 'error' : ''}>
              <span>Função: </span>
              <select id="funcao" name="funcao" value={userData.funcao} onChange={handleChange}>
                <option value="">SELECIONE UMA FUNÇÃO</option>
                {funcoes.map((funcao) => (
                  <option key={funcao.id} value={funcao.descricao}>
                    {funcao.descricao}
                  </option>
                ))}
              </select>
              <a href="/cadastro-funcao" className="cent-img">
              <img src="./mais.png" alt="Descrição da imagem" />
              </a>
            </label>


            <label htmlFor="departamento" className={userData.departamento.trim() === '' ? 'error' : ''}>
              <span>Departamento: </span>
              <select id="departamento" name="departamento" value={userData.departamento} onChange={handleChange}>
                <option value="">SELECIONE UM DEPARTAMENTO</option>
                {departamentos.map(departamento => (
                  <option key={departamento.id} value={departamento.descricao}>{departamento.descricao}</option>
                ))}
              </select>
              <a href="/cadastro-departamento" className="cent-img">
              <img src="./mais.png" alt="Descrição da imagem" />
              </a>
            </label>

            <label htmlFor="estrutura" className={userData.estrutura.trim() === '' ? 'error' : ''}>
              <span>Estrutura: </span>
              <select id="estrutura" name="estrutura" value={userData.estrutura} onChange={handleChange}>
                <option value="">SELECIONE UMA ESTRUTURA</option>
                {estruturas.map(estrutura => (
                  <option key={estrutura.id} value={estrutura.descricao}>{estrutura.descricao}</option>
                ))}
              </select>
            </label>

            <label htmlFor="admissao" className={userData.dataAdmissao.trim() === '' ? 'error' : ''}>
            <span>Admissão: </span>
            <input type="date" id="admissao" name="dataAdmissao" value={userData.dataAdmissao} onChange={handleChange} />
            <a className="cent-img">
              <img src="./cal.png" alt="Descrição da imagem" />
              </a>
            </label>

            <label htmlFor="demissao">
              <span>Demissão:</span>
            <input type="date" id="demissao" name="dataDemissao" value={userData.dataDemissao} onChange={handleChange} />
            <a className="cent-img">
              <img src="./cal.png" alt="Descrição da imagem" />
              </a>
            </label>

            <label  htmlFor="motivoDemissao"><span>Motivo de Demissão:</span><input type="text" id="motivoDemissao" name="motivoDemissao" value={userData.motivoDemissao} onChange={handleChange}/></label>
            
            <label htmlFor="ctps"><span>CTPS:</span><input type="text" id="ctps" name="ctps" value={userData.ctps} onChange={handleChange} /></label>

            <div className="btns">
                <button className="button-salvar" type="submit">Salvar</button>
                <button className="button-cancelar" type="submit">Cancelar</button>
            </div>
            {message && <div className="message">{message}</div>}
          </form>
        </div>
        <div className={`conteudo-aba ${activeTab === 1 ? 'ativo' : ''}`}>
          <form className="formulario" onSubmit={handleSubmit}>
            <label htmlFor="endereco"><span>Endereço: </span><input type="text" id="endereco" name="endereco" value={userData.endereco} onChange={handleChange} onBlur={handleCEPBlur} /></label>
            
            <label htmlFor="bairro"><span>Bairro: </span><input type="text" id="bairro" name="bairro" value={userData.bairro} onChange={handleChange}/></label>

            <label htmlFor="cidade"><span>Cidade: </span><input type="text" id="cidade" name="cidade" value={userData.cidade} onChange={handleChange}/></label>

            <label htmlFor="estado"><span>Estado/Distrito: </span><input type="text" id="estado" name="estado" value={userData.estado} onChange={handleChange}/></label>

            <label htmlFor="cep"><span>CEP: </span><input type="number" id="cep" name="cep" value={userData.cep} onChange={handleChange} onBlur={handleCEPBlur} /></label>

            <label htmlFor="telefone"><span>Telefone:</span><input type="number" id="telefone" name="telefone" value={userData.telefone} onChange={handleChange}/></label>

            <label htmlFor="email"><span>E-mail:</span><input type="text" id="email" name="email" value={userData.email} onChange={handleChange}/></label>

            <label htmlFor="RG"><span>RG:</span><input type="text" id="RG" name="rg" value={userData.rg} onChange={handleChange}/></label>

            <label htmlFor="expedicao"><span>Expedição:</span><input type="date" id="expedicao" name="expedicao" value={userData.expedicao} onChange={handleChange}/></label>

            <label htmlFor="expeditor"><span>Orgão Expeditor:</span><input type="text" id="expeditor" name="orgaoExpeditor" value={userData.orgaoExpeditor} onChange={handleChange}/></label>

            <label htmlFor="UF"><span>UF:</span><input type="text" id="UF" name="uf" value={userData.uf} onChange={handleChange} /></label>

            <label htmlFor="Pai"><span>Pai:</span><input type="text" id="Pai" name="pai" value={userData.pai} onChange={handleChange}/></label>

            <label htmlFor="mae"><span>Mãe:</span><input type="text" id="mae" name="mae" value={userData.mae} onChange={handleChange} /></label>

            <label htmlFor="nascimento"><span>nascimento:</span><input type="date" id="nascimento" name="nascimento" value={userData.nascimento} onChange={handleChange}/></label>

            <label htmlFor="genero"><span>Genero:</span><input type="checkbox" id="genero" name="genero" value={userData.genero} onChange={handleChange}/>Masculino <input type="checkbox" id="genero" name="genero" value={userData.genero} onChange={handleChange}/>Feminino</label>

            <label htmlFor="naturalidade"><span>Naturalidade:</span><input type="text" id="naturalidade" name="naturalidade" value={userData.naturalidade} onChange={handleChange}/></label>
          
            <label htmlFor="escolaridade" className={userData.escolaridade.trim() === '' ? 'error' : ''}>
              <span>Escolaridade: </span>
              <select id="escolaridade" name="escolaridade" value={userData.escolaridade} onChange={handleChange}>
                <option value="">SELECIONE SUA ESCOLARIDADE</option>
                {escolaridades.map((escolaridade) => (
                  <option key={escolaridade.id} value={escolaridade.valor}>
                    {escolaridade.descricao}
                  </option>
                ))}
              </select>
            </label>



            <div className="btns">
                <button className="button-salvar" type="submit">Salvar</button>
                <button className="button-cancelar" type="submit">Cancelar</button>
            </div>
           
          </form>
          {message && <div className="message">{message}</div>}
        </div>
        <div className={`conteudo-aba ${activeTab === 2 ? 'ativo' : ''}`}>
            <img src="./site1.png" alt="PÁGINA EM CONSTRUÇÃO" />
        </div>
        <div className={`conteudo-aba ${activeTab === 3 ? 'ativo' : ''}`}>
        <img src="./site1.png" alt="PÁGINA EM CONSTRUÇÃO" />
        </div>
      </div>
    </div>
  );
};

export default CadastroUsuario;
