import React from 'react';

const MovimentacaoInit = () => {
    return (
        <div>
            <span>MOVIMENTAÇÃO</span>
        </div>
    );
};

export default MovimentacaoInit;