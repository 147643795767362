import React from 'react';

const ManutencaoInit = () => {
    return (
        <div>
            <span>Manutencao</span>
        </div>
    );
};

export default ManutencaoInit;