import React from 'react';
import axios from 'axios'; // Importe o Axios
import './CadastroEmpresas.css';

function EmpresaFormulario() {
  // Handler para lidar com o envio do formulário
  const handleSubmit = async (event) => {
    event.preventDefault();
  
    const formData = new FormData(event.target);
    const formProps = Object.fromEntries(formData);
  
    // Converte os valores dos campos de checkbox para booleanos
    formProps.RECC = !!formProps.RECC; // Converta 'on' para true, ou undefined para false
    formProps.REPA = !!formProps.REPA;
    formProps.REPP = !!formProps.REPP;
  
    console.log(formProps); // Verifica os dados antes de enviar
  
    try {
      await axios.post('https://pontoweb.kiponto.com:8900/cadastro-empresa', formProps);
      alert('Empresa cadastrada com sucesso!');
    } catch (error) {
      alert('Erro ao cadastrar empresa: ' + error.message);
    }
  };

  return (
    <div className="BodEmpresas">
      <div className="formulario-Empresa">
        <div className="header-formulario-Empresa">
          <span>EMPRESA</span>
        </div>
        <h2>DADOS DE IDENTIFICAÇÃO</h2>

        <form className="formulario-Empresa" onSubmit={handleSubmit} encType="multipart/form-data">
          <label htmlFor="nome"><span>Nome: </span><input type="text" id="nome" name="nome" placeholder="Nome Completo" required /></label>
          <label htmlFor="cnpj"><span>CNPJ: </span><input type="text" id="cnpj" name="cnpj" placeholder="000.000.000-00" required /></label>
          <label htmlFor="inscricao"><span>Inscrição: </span><input type="number" id="inscricao" name="inscricao" /></label>

          <div className="MinhaLinha"></div>
          <h2>DADOS GENÉRICOS</h2>
          <label htmlFor="endereco"><span>Endereço: </span><input type="text" id="endereco" name="endereco" /></label>
          <label htmlFor="bairro"><span>Bairro: </span><input type="text" id="bairro" name="bairro" /></label>
          <label htmlFor="cidade"><span>Cidade: </span><input type="text" id="cidade" name="cidade" /></label>
          <label htmlFor="cep"><span>CEP: </span><input type="number" id="cep" name="cep" /></label>
          <label htmlFor="estado"><span>Estado/Distrito: </span><input type="text" id="estado" name="estado" /></label>
          <label htmlFor="pais"><span>País: </span><input type="text" id="pais" name="pais" /></label>
          <label htmlFor="telefone"><span>Telefone: </span><input type="text" id="telefone" name="telefone" /></label>

          <div className="MinhaLinha"></div>
          <h2>DADOS RESPONSÁVEL</h2>
          <label htmlFor="nomeResponsavel"><span>Nome: </span><input type="text" id="nomeResponsavel" name="nomeResponsavel" /></label>
          <label htmlFor="cargo"><span>Cargo: </span><input type="text" id="cargo" name="cargo" /></label>
          <label htmlFor="Email"><span>Email: </span><input type="text" id="Email" name="Email" /></label>

          <div className="MinhaLinha"></div>
          <h2>PORTARIA 671</h2>
          <span>Tipos de REPs utilizado por esta empresa</span>
          <label htmlFor="RECC"><span>REC - C: </span><input type="checkbox" id="RECC" name="RECC" /></label>
          <label htmlFor="REPA"><span>REP - A: </span><input type="checkbox" id="REPA" name="REPA" /></label>
          <label htmlFor="REPP"><span>REP - P: </span><input type="checkbox" id="REPP" name="REPP" /></label>

          <div className="btns">
            <button className="button-salvar" type="submit">Salvar</button>
            <button className="button-cancelar" type="reset">Cancelar</button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default EmpresaFormulario;
