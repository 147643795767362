import React, { useState } from 'react';
import axios from 'axios';
import './FuncaoInit.css';

function CadastroDepartamento() {
  const [departamento, setDepartamento] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post('https://pontoweb.kiponto.com:8900/cadastro-departamento', { departamento });
      alert('Departamento cadastrado com sucesso!');
      setDepartamento('');
    } catch (error) {
      alert('Erro ao cadastrar o departamento.');
      console.error('Erro:', error);
    }
  };

  return (
    <div className="BodEmpresas">
      <div className="formulario-funcao">
        <div className="header-formulario-Empresa">
          <span>Departamento</span>
        </div>
        <form className="formulario-Empresa" onSubmit={handleSubmit} encType="multipart/form-data">
          <label htmlFor="departamento">
            <span>Descrição: </span>
            <input type="text" id="departamento" name="departamento" value={departamento} onChange={(e) => setDepartamento(e.target.value)} required />
          </label>
          <div className="btn-funcao">
            <button className="button-salvar" type="submit">Salvar</button>
            <button className="button-cancelar" type="reset">Cancelar</button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default CadastroDepartamento;
