import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import styles from './components/LoginForm.module.css'; // Importe os estilos
import axios from 'axios';

const LoginForm = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loginSuccess, setLoginSuccess] = useState(false);
  const [loginError, setLoginError] = useState(null); // Novo estado para mensagens de erro
  const { isAuthenticated, login } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (loginSuccess && isAuthenticated) {
      navigate('/Home');
    }
  }, [loginSuccess, isAuthenticated, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post('https://pontoweb.kiponto.com:8900/login', {
        username: username,
        password: password,
      });

      if (response.data.success) {
        setLoginSuccess(true);
        login();
      } else {
        // Se o login não for bem-sucedido, definir a mensagem de erro
        setLoginError('Credenciais inválidas. Por favor, tente novamente.');
      }
    } catch (error) {
      console.error('Erro durante a autenticação:', error.message);
      setLoginError('Ocorreu um erro durante a autenticação. Tente novamente mais tarde.');
    }
  };

  return (
    <div className={styles.loginForm}>
      <header>
        <img src="BarraSuperior.png" alt="Top Bar" style={{ width: '100%' }} />
      </header>

      <main className={styles.centerForm} style={{ marginBottom: '50px', marginTop: '100px' }}>
        <img src="kiponto.png" alt="Concept" height="110" />
      </main>

      <section className={styles.form}>
        {loginError && <div className={styles.error}>{loginError}</div>}

        <form onSubmit={handleSubmit}>
          <label htmlFor="username">Usuário:</label>
          <input
            type="text"
            id="username"
            className={styles.input}
            required
            placeholder="Digite seu usuário"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />

          <label htmlFor="password">Senha:</label>
          <input
            type="password"
            id="password"
            className={styles.input}
            required
            placeholder="Digite sua senha"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />

          <button type="submit" className={styles.button}>
            Enviar
          </button>
        </form>
      </section>
    </div>
  );
};

export default LoginForm;
