import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'; // Importe o componente Link do React Router
import './usuarioRel.css';

function UsuarioRel() {
  const [funcionarios, setFuncionarios] = useState([]);
  const [paginaAtual, setPaginaAtual] = useState(1);
  const [funcionariosPorPagina, setFuncionariosPorPagina] = useState(10); // Começa com 10 funcionários por página
  const [termoBusca, setTermoBusca] = useState('');
  const [marcarTodos, setMarcarTodos] = useState(false);
  const [modalAberto, setModalAberto] = useState(false);
  const [funcionarioParaExcluir, setFuncionarioParaExcluir] = useState(null);

  useEffect(() => {
    const fetchFuncionarios = async () => {
      try {
        const response = await fetch('https://pontoweb.kiponto.com:8900/dados-funcionarios');
        if (!response.ok) {
          throw new Error('Dados não puderam ser obtidos');
        }
        const data = await response.json();
        setFuncionarios(data);
      } catch (error) {
        console.error("Erro ao buscar dados dos funcionários:", error);
      }
    };

    fetchFuncionarios();
  }, []);

  const ultimoFuncionarioIndex = paginaAtual * funcionariosPorPagina;
  const primeiroFuncionarioIndex = ultimoFuncionarioIndex - funcionariosPorPagina;

  const funcionariosFiltrados = funcionarios.filter(funcionario => (
    funcionario.nome.toLowerCase().includes(termoBusca.toLowerCase()) ||
    funcionario.folha.toString().toLowerCase().includes(termoBusca.toLowerCase()) ||
    funcionario.cpf.toLowerCase().includes(termoBusca.toLowerCase()) ||
    funcionario.departamento.toLowerCase().includes(termoBusca.toLowerCase())
  ));

  const funcionariosAtuais = funcionariosFiltrados.slice(primeiroFuncionarioIndex, ultimoFuncionarioIndex);

  const handleChange = (e) => {
    setFuncionariosPorPagina(Number(e.target.value));
    setPaginaAtual(1); // Resetar para a primeira página após mudança do número de itens por página
  };

  const handleBusca = (e) => {
    setTermoBusca(e.target.value);
    setPaginaAtual(1); // Resetar para a primeira página ao iniciar uma nova busca
  };

  const toggleMarcarTodos = () => {
    setMarcarTodos(!marcarTodos);
  };

  const handleCheckboxChange = async (index) => {
    const updatedFuncionarios = [...funcionarios];
    updatedFuncionarios[index].selecionado = !updatedFuncionarios[index].selecionado;
    setFuncionarios(updatedFuncionarios);
  
    // Verificar se pelo menos um funcionário está selecionado
    const algumSelecionado = updatedFuncionarios.some(funcionario => funcionario.selecionado);
    
    // Abra o modal de confirmação apenas se pelo menos um funcionário estiver selecionado
    if (algumSelecionado) {
      setFuncionarioParaExcluir(updatedFuncionarios[index]);
      setModalAberto(true);
    } else {
      // Se nenhum funcionário estiver selecionado, defina funcionarioParaExcluir como null
      setFuncionarioParaExcluir(null);
    }
  };
  
  

  const confirmarExclusao = async () => {
    try {
      await fetch('https://pontoweb.kiponto.com:8900/excluir-usuario', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ id: funcionarioParaExcluir.id })
      });
      console.log('Usuário excluído com sucesso');
    } catch (error) {
      console.error('Erro ao excluir usuário:', error);
    } finally {
      // Feche o modal de confirmação
      setModalAberto(false);
    }
  };

  // Função para formatar CPF, se necessário
  const formatarCPF = (cpf) => cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");

  // Botões de página anterior e próxima
  const paginaAnterior = () => {
    if (paginaAtual > 1) {
      setPaginaAtual(paginaAtual - 1);
    }
  };

  const paginaSeguinte = () => {
    if (ultimoFuncionarioIndex < funcionariosFiltrados.length) {
      setPaginaAtual(paginaAtual + 1);
    }
  };

  return (
    <div className="bodyUse">
      <div className="menu-lateral">
        {/* Menu lateral esquerdo */}
        <ul>
          <li><Link to="/Home"><img src="./voltar.png" alt="Voltar" /></Link></li>
          <li><Link to="/empresa">Empresas</Link></li>
          <li><Link to="/horario">Horários</Link></li>
          <li><Link to="/relatorio-funcionario" style={{ fontWeight: 'bold' }}>Funcionários</Link></li>
          <div className="separador"></div>
          <li><Link to="/banco-de-horas">Banco de Horas</Link></li>
          <div className="separador"></div>
          <li><Link to="/eventos">Eventos</Link></li>
          <li><Link to="/atividades">Atividades</Link></li>
          <li><Link to="/funcao">Funções</Link></li>
          <li><Link to="/departamentos">Departamentos</Link></li>
          <li><Link to="/estruturas">Estruturas</Link></li>
          <li><Link to="/centro-de-custos">Centro de Custos</Link></li>
          <li><Link to="/motivos-de-demissoes">Motivos de Demissões</Link></li>
          <li><Link to="/escolaridades">Escolaridades</Link></li>
          <div className="separador"></div>
          <li><Link to="/feriados">Feriados</Link></li>
          <li><Link to="/cidades">Cidades</Link></li>
        </ul>
      </div>
      <div className="cadastro">
        <div className="topo">
          <div className="barra" style={{ display: 'flex', flexDirection: 'row' }}>
            <div className="titulo" style={{ display: 'flex', flexDirection: 'row' }}>
              <img src="fun.png" alt="" width="50" />
              <div className="tituloRelat">FUNCIONÁRIOS</div>
            </div>
            <div className="adc-exc" style={{ display: 'flex', flexDirection: 'row' }}>
              <Link to="/cadastro-funcionario">
                <img src="plus.png" alt="" style={{ marginRight: '15px' }} />
              </Link>
              <img src="trash.png" alt="" onClick={() => setModalAberto(true)} />
            </div>

          </div>

          <div className="barra-inferior" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <div className="resultados">
              <div className="tituloRelat"> Mostrar
                <span className="select">
                  <select name="opcao" onChange={handleChange}>
                    <option value="10">10</option>
                  </select>
                </span>
                resultados por página
              </div>
            </div>

            <div className="busca" style={{ display: 'flex', flexDirection: 'row' }}>
            <div className="buscarNew">Buscar</div><input type="search" value={termoBusca} onChange={handleBusca} />
              <img src="search.png" alt="" width="20" />
            </div>
          </div>
        </div>

        <table className="table">
        <thead>
          <tr>
            <th><input type="checkbox" checked={marcarTodos} onChange={toggleMarcarTodos} /></th>
            <th>Nome</th>
            <th>Nº Folha</th>
            <th>CPF</th>
            <th>Departamento</th>
          </tr>
        </thead>
        <tbody>
          {funcionariosAtuais.map((funcionario, index) => (
            <tr key={funcionario.id}>
              <td><input type="checkbox" checked={funcionario.selecionado} onChange={() => handleCheckboxChange(index)}/></td>
              <td><Link to={`/funcionarios/${funcionario.id}`} style={{ color: '#000' }}>{funcionario.nome}</Link></td>
              <td>{funcionario.folha}</td>
              <td>{formatarCPF(funcionario.cpf)}</td>
              <td>{funcionario.departamento}</td>
            </tr>
          ))}
        </tbody>
      </table>
        <div className="footer">
          <div className="informacao-paginacao">
            Mostrar {primeiroFuncionarioIndex + 1} a {ultimoFuncionarioIndex > funcionariosFiltrados.length ? funcionariosFiltrados.length : ultimoFuncionarioIndex} de {funcionariosFiltrados.length} funcionários
          </div>
          <div className="paginacao">
            {/* Botões de paginação */}
            {paginaAtual > 1 && (
              <button onClick={paginaAnterior}>Anterior</button>
            )}
            {[...Array(Math.ceil(funcionariosFiltrados.length / funcionariosPorPagina)).keys()].map(numero => (
              <button key={numero + 1} onClick={() => setPaginaAtual(numero + 1)}>
                {numero + 1}
              </button>
            ))}
            {ultimoFuncionarioIndex < funcionariosFiltrados.length && (
              <button onClick={paginaSeguinte}>Próxima</button>
            )}
          </div>
        </div>

        {/* Modal de confirmação */}
        {modalAberto && funcionarioParaExcluir && (
          <div className="modal">
            <div className="modal-conteudo">
              <div className="titleModal">Deseja excluir o funcionário "{funcionarioParaExcluir.nome}" ?</div>
              <button onClick={confirmarExclusao}>Confirmar</button>
              <button onClick={() => setModalAberto(false)}>Cancelar</button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default UsuarioRel;
