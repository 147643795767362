import React, { useState, useEffect } from 'react';
import axios from 'axios'; // Importe o axios
import { Link } from 'react-router-dom';
import Calendar from 'react-calendar';
import styles from './components/HomeInit.module.css'; // Importe os estilos do arquivo CSS

// Importe os Ícones
import { FaHome } from 'react-icons/fa';
import { BsPeopleFill } from "react-icons/bs";
import { GrUpdate } from "react-icons/gr";
import { GrDocumentConfig } from "react-icons/gr";
import { FaNewspaper } from "react-icons/fa";
import { MdCalculate } from "react-icons/md";
import { IoExit } from "react-icons/io5";

const HomeInit = () => {
  const [showCadastroSubMenu, setShowCadastroSubMenu] = useState(false);
  const [activeTab, setActiveTab] = useState("Resumo diário"); 
  const [funcionariosTrabalhando, setFuncionariosTrabalhando] = useState(0);


  const MAX_FUNCIONARIOS = 100;
  const percentualTrabalhando = (funcionariosTrabalhando / MAX_FUNCIONARIOS) * 100;


  // Função para lidar com o hover do item "CADASTRO"
  const handleCadastroHover = () => {
    setShowCadastroSubMenu(true);
  };

  // Função para lidar com o fim do hover do item "CADASTRO"
  const handleCadastroMouseOut = () => {
    setShowCadastroSubMenu(false);
  };

  const [showMovimentacaoSubMenu, setShowMovimentacaoSubMenu] = useState(false);
  // Função para lidar com o hover do item "CADASTRO"
  const handleMovimentacaoHover = () => {
    setShowMovimentacaoSubMenu(true);
  };

  // Função para lidar com o fim do hover do item "CADASTRO"
  const handleMovimentacaoMouseOut = () => {
    setShowMovimentacaoSubMenu(false);
  };


  // Estados individuais para cada barra de progresso
  const [progress2, setProgress2] = useState(0);
  const [progress3, setProgress3] = useState(0);
  const [progress4, setProgress4] = useState(0);
  const [progress5, setProgress5] = useState(0);
  const [progress6, setProgress6] = useState(0);
  

  // Efeito para atualizar cada barra de progresso independentemente
  useEffect(() => {
    
    const interval2 = setInterval(() => {
      if (progress2 < 42) {
        setProgress2(progress2 + 1);
      }
    }, 50); // Ajuste o intervalo conforme necessário

    const interval3 = setInterval(() => {
      if (progress3 < 64) {
        setProgress3(progress3 + 1);
      }
    }, 50); // Ajuste o intervalo conforme necessário

    const interval4 = setInterval(() => {
      if (progress4 < 33) {
        setProgress4(progress4 + 1);
      }
    }, 50); // Ajuste o intervalo conforme necessário

    const interval5 = setInterval(() => {
      if (progress5 < 75) {
        setProgress5(progress5 + 1);
      }
    }, 50); // Ajuste o intervalo conforme necessário

    const interval6 = setInterval(() => {
      if (progress6 < 95) {
        setProgress6(progress6 + 1);
      }
    }, 50); // Ajuste o intervalo conforme necessário

    // Limpa os intervalos quando o componente é desmontado
    return () => {
      clearInterval(interval2);
      clearInterval(interval3);
      clearInterval(interval4);
      clearInterval(interval5);
      clearInterval(interval6);
    };
  }, [progress2, progress3, progress4, progress5, progress6]);

  // Código adicionado
  const [calendario, setCalendario] = useState({});
  const [loading, setLoading] = useState(true);
  const [date, setDate] = useState(new Date());

  useEffect(() => {
    const fetchCalendario = async () => {
      try {
        const response = await axios.get('https://pontoweb.kiponto.com:8900/calendario-aniversariantes');
        setCalendario(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Erro ao obter o calendário de aniversariantes:', error.message);
      }
    };

    fetchCalendario();
  }, []);

  useEffect(() => {
    const fetchFuncionariosTrabalhando = async () => {
      try {
        const response = await axios.get('https://pontoweb.kiponto.com:8900/quantidade-funcionarios-trabalhando');
        setFuncionariosTrabalhando(response.data.quantidade);
      } catch (error) {
        console.error('Erro ao obter quantidade de funcionários trabalhando:', error.message);
      }
    };

    fetchFuncionariosTrabalhando();
  }, []);

  const calendarTileStyles = {
    aniversariante: {
      width: '8px',
      height: '8px',
      marginTop: '-8px',
      backgroundColor: '#CFD1E4', // Cor de fundo para aniversariantes
      borderRadius: '50%', // Borda circular
    },
  };
  
  // Atualize a função tileContent para retornar a classe CSS apropriada com base na data
  const tileContent = ({ date, view }) => {
    if (view === 'month') {
      const dia = date.getDate();
      const mes = date.getMonth() + 1;
      if (calendario[mes] && calendario[mes].find(aniversariante => aniversariante.dia === dia)) {
        return (
          <div style={calendarTileStyles.aniversariante} className="aniversariante-marker"></div>
        );
      }
    }
    return null;
  };
  
  
  

  return (
    <div className={styles.body}>
      <header className={styles.header}>
        <img src="kiponto.png" alt="Logo da Empresa" className={styles.mainImage}/>
      </header>

      <main>
        <aside>
          <nav className={styles.buttonContent}>
            <a href="/Home" className={styles.link} style={{background: '#fff', color: '#0E4749'}}>
            <FaHome className={styles.icon}/>
              <span>Página Inicial</span>
              </a>
              <div
              className={styles.link}
              onMouseOver={handleCadastroHover}
              onMouseOut={handleCadastroMouseOut}
            >
              <BsPeopleFill className={styles.icon}/>
              <span>Cadastro</span>
              {/* Submenu de "CADASTRO" */}
              {showCadastroSubMenu && (
                <div className={styles.subMenu}>
                  <Link to="/empresa">Empresas</Link>
                  <Link to="/horario">Horários</Link>
                  <Link to="/relatorio-funcionario">Funcionários</Link>
                  <div className="separador"></div>
                  <Link to="/Home">Banco de Horas</Link>
                  <div className="separador"></div>
                  <Link to="/Home">Eventos</Link>
                  <Link to="/Home">Atividades</Link>
                  <Link to="/funcao">Funções</Link>
                  <Link to="/departamentos">Departamentos</Link>
                  <Link to="/estruturas">Estruturas</Link>
                  <Link to="/Home">Centro de Custos</Link>
                  <Link to="/motivos-de-demissoes">Motivos de Demissões</Link>
                  <Link to="/escolaridades">Escolaridades</Link>
                  <div className="separador"></div>
                  <Link to="/Home">Feriados</Link>
                  <Link to="/Home">Cidades</Link>
                </div>
              )}
            </div>
            <div
              className={styles.link}
              onMouseOver={handleMovimentacaoHover}
              onMouseOut={handleMovimentacaoMouseOut}
            >
              <BsPeopleFill className={styles.icon}/>
              <span>Movimentação</span>
              {/* Submenu de "CADASTRO" */}
              {showMovimentacaoSubMenu && (
                <div className={styles.subMenu}>
                  <Link to="/cartao-ponto">Cartão de Ponto</Link>
                  <div className="separador"></div>
                  <Link to="/Home">Solicitações Central do Funcionario</Link>
                  <Link to="/Home">Assinatura Eletronica de Cartão Ponto</Link>
                  <Link to="/Home">Encessamento de Cãlculos</Link>
                  <Link to="/Home">Importar Arquivo AFD</Link>
                  <Link to="/Home">Lançamentos de Eventos</Link>
                  <Link to="/Home">Lançamentos de Atividades</Link>
                  <Link to="/Home">Gestão de Arquivo</Link>
                  <div className="separador"></div>
                  <Link to="/Home">Comunicação</Link>
                  <Link to="/localizacao">Log de Localização</Link>
                </div>
              )}
            </div>
            <a href=" " className={styles.link}>
              <GrDocumentConfig className={styles.icon}/>
              <span>Manutenção</span>
            </a>
            <a href=" " className={styles.link}>
              <FaNewspaper className={styles.icon}/>
              <span>Relatorio</span>
            </a>
            <a href=" " className={styles.link}>
              <MdCalculate className={styles.icon}/>
              <span>Calculadora</span>
            </a>
            <a href="/logout" className={styles.link}>
              <IoExit className={styles.icon}/>
              <span>Sair</span>
            </a>


          </nav>
        </aside>

        <section className={styles.sectionImage}>
          <nav className={styles.tabs}>
            <div
              className={`${styles.tab} ${activeTab === "Resumo diário" ? styles.active : ""}`}
              onClick={() => setActiveTab("Resumo diário")}
            >
              RESUMO DIÁRIO
            </div>
            <div
              className={`${styles.tab} ${activeTab === "Equipamentos" ? styles.active : ""}`}
              onClick={() => setActiveTab("Equipamentos")}
            >
              EQUIPAMENTOS
            </div>
            <div
              className={`${styles.tab} ${activeTab === "Rotatividade" ? styles.active : ""}`}
              onClick={() => setActiveTab("Rotatividade")}
            >
              ROTATIVIDADE
            </div>
            <div
              className={`${styles.tab} ${activeTab === "Perfil" ? styles.active : ""}`}
              onClick={() => setActiveTab("Perfil")}
            >
              PERFIL
            </div>
            <div
              className={`${styles.tab} ${activeTab === "Calendário" ? styles.active : ""}`}
              onClick={() => setActiveTab("Calendário")}
            >
              CALENDÁRIO
            </div>
          </nav>

          {activeTab === "Resumo diário" && (
            <section className={styles.sectionContent}>
              <div className={styles.squareContainer}>
                <div className={styles.square}>
                  <div className={styles.titleGrafico}>Funcionários Trabalhando</div>
                  <div className={styles.num}>{funcionariosTrabalhando}</div>
                  <div className={styles.progressBar}>
                    <div className={styles.progressBackground}></div>
                    <div className={styles.progressForeground} style={{ width: `${percentualTrabalhando}%` }}>
                      {percentualTrabalhando.toFixed(0)}%
                    </div>
                  </div>
                </div>
                <div className={styles.square}>
                <div className={styles.titleGrafico}>Funcionários Faltantes</div>
                  <div className={styles.num}>10</div>
                  <div className={styles.progressBar}>
                    <div className={styles.progressBackground}></div>
                    <div className={styles.progressForeground} style={{ width: `${progress2}%` }}>
                      {progress2}%
                    </div>
                  </div>
                </div>
                <div className={styles.square}>
                <div className={styles.titleGrafico}>Funcionários em Folga</div>
                  <div className={styles.num}>12</div>
                  <div className={styles.progressBar}>
                    <div className={styles.progressBackground}></div>
                    <div className={styles.progressForeground} style={{ width: `${progress3}%` }}>
                      {progress3}%
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.squareContainer}>
                <div className={styles.square}>
                <div className={styles.titleGrafico}>Funcionários de Férias</div>
                  <div className={styles.num}>5</div>
                  <div className={styles.progressBar}>
                    <div className={styles.progressBackground}></div>
                    <div className={styles.progressForeground} style={{ width: `${progress4}%` }}>
                      {progress4}%
                    </div>
                  </div>
                </div>
                <div className={styles.square}>
                <div className={styles.titleGrafico}>Funcionários Afastados</div>
                  <div className={styles.num}>8</div>
                  <div className={styles.progressBar}>
                    <div className={styles.progressBackground}></div>
                    <div className={styles.progressForeground} style={{ width: `${progress5}%` }}>
                      {progress5}%
                    </div>
                  </div>
                </div>
                <div className={styles.square}>
                <div className={styles.titleGrafico}>Funcionários com Ausência Justificada</div>
                  <div className={styles.num}>7</div>
                  <div className={styles.progressBar}>
                    <div className={styles.progressBackground}></div>
                    <div className={styles.progressForeground} style={{ width: `${progress6}%` }}>
                      {progress6}%
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}
          {activeTab === "Equipamentos" && (
            <section className={styles.sectionContent}>
                <img src="./site.png" alt="PÁGINA EM CONSTRUÇÃO" />
            </section>
          )}

          {activeTab === "Rotatividade" && (
            <section className={styles.sectionContent}>
             <img src="./site.png" alt="PÁGINA EM CONSTRUÇÃO" />
            </section>
          )}

          {activeTab === "Perfil" && (
            <section className={styles.sectionContent}>
             <img src="./site.png" alt="PÁGINA EM CONSTRUÇÃO" />
            </section>
          )}

          {activeTab === "Calendário" && (
            <section className={styles.sectionContent}>
              <div className="calendario-container">
                {loading ? (
                  <p className="calendario-loading">Carregando...</p>
                ) : (
                  <div>
                    <div className="calendario-selectors">
                    <div style={{ position: 'relative', display: 'inline-block' }}>
                <select
                  id="monthSelect"
                  value={date.getMonth() + 1} // Adicionando 1 pois os meses começam em 0
                  onChange={e => setDate(new Date(date.getFullYear(), e.target.value - 1, date.getDate()))} // Subtraindo 1 pois os meses começam em 0
                  style={{ paddingRight: '25px' }} // Ajusta o espaço para a imagem de seta
                >
                  {Array.from({ length: 12 }, (_, i) => (
                    <option key={i + 1} value={i + 1}>{new Date(date.getFullYear(), i, 1).toLocaleString('pt-BR', { month: 'long' }).replace(/^\w/, c => c.toUpperCase())}</option>
                  ))}
                </select>
                <img src="seta.png" alt="Seta" style={{ position: 'absolute', top: '50%', right: '5px', transform: 'translateY(-50%)', width: '15px', height: '10px' }} />
              </div>
              <div style={{ position: 'relative', display: 'inline-block', marginLeft: '60px' }}>
                <select
                  id="yearSelect"
                  value={date.getFullYear()}
                  onChange={e => setDate(new Date(e.target.value, date.getMonth(), date.getDate()))}
                  style={{ paddingRight: '50px' }} // Ajusta o espaço para a imagem de seta
                >
                  {Array.from({ length: 10 }, (_, i) => (
                    <option key={i} value={new Date().getFullYear() + i}>{new Date().getFullYear() + i}</option>
                  ))}
                </select>
                <img src="seta.png" alt="Seta" style={{ position: 'absolute', top: '50%', right: '5px', transform: 'translateY(-50%)', width: '15px', height: '10px' }} />
              </div>
           </div>
           <Calendar
            className="calendario"
            value={date}
            onChange={setDate}
            tileContent={tileContent}
            tileClassName={({ date }) => {
              const dia = date.getDate();
              const mes = date.getMonth() + 1;
              if (calendario[mes] && calendario[mes].find(aniversariante => aniversariante.dia === dia)) {
                return 'aniversariante';
              }
              return null;
            }}
            locale="pt-BR"
            formatShortWeekday={(locale, date) => {
              const weekdays = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'];
              return weekdays[date.getDay()];
            }}
            showNavigation={false}
          />
<table className="tabela-aniversariantes">
    <thead>
      <tr>
        <th>Data</th>
        <th>Descrição</th>
        <th>Evento/Atividade</th>
      </tr>
    </thead>
    <tbody>
      {Object.entries(calendario).map(([mes, aniversariantes]) => (
        <React.Fragment key={mes}>
          {aniversariantes.map(aniversariante => (
            <tr key={`${aniversariante.nome}-${aniversariante.dia}`}>
              <td>{`${aniversariante.dia}/${mes}/${aniversariante.ano || new Date().getFullYear()}`}</td>
              <td>{aniversariante.nome}</td>
              <td>ANIVERSÁRIO</td>
            </tr>
          ))}
        </React.Fragment>
      ))}
    </tbody>
  </table>

    </div>
  )}
</div>


            </section>
          )}
        </section>
      </main>
    </div>
  );
};

export default HomeInit;