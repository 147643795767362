import { Link } from 'react-router-dom';
import './usuarioRel.css';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

function FuncionarioDetalhes() {
  const { id } = useParams();
  const [funcionario, setFuncionario] = useState(null);
  const [visualizarFoto, setVisualizarFoto] = useState(false); // Novo estado para controle da visualização
  const [fotoSelecionada, setFotoSelecionada] = useState(''); // Novo estado para armazenar a foto atualmente selecionada


  useEffect(() => {
    const fetchFuncionario = async () => {
      try {
        const response = await fetch(`https://pontoweb.kiponto.com:8900/dados-funcionarios/${id}`);
        if (!response.ok) {
          throw new Error('Funcionário não encontrado');
        }
        const data = await response.json();
        setFuncionario(data);
      } catch (error) {
        console.error("Erro ao buscar dados do funcionário:", error);
      }
    };

    fetchFuncionario();
  }, [id]);

  const handlePrint = () => {
    window.print();
  };

  // Função para determinar se é manhã ou tarde
  const getPeriod = (timestamp) => {
    const hour = new Date(timestamp).getHours();
    return hour >= 12 ? 'Tarde' : 'Manhã';
  };

  // Função para obter o nome do mês e o dia da semana
  const getFormattedDate = (timestamp) => {
    const date = new Date(timestamp);
    const daysOfWeek = ['Domingo', 'Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira', 'Sábado'];
    const months = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];
    const dayOfWeek = daysOfWeek[date.getDay()];
    const month = months[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();
    const hour = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');
    return `${dayOfWeek}, ${day} de ${month} de ${year} ${hour}:${minutes}:${seconds}`;
  };

  // Função para calcular horas extras
  const calculateExtraHours = (timestamp) => {
    const date = new Date(timestamp);
    const hour = date.getHours();
    const minutes = date.getMinutes();

    // Defina os horários de entrada e saída normais
    const startMorningHour = 7;
    const endMorningHour = 12;
    const startAfternoonHour = 14;
    const endAfternoonHour = 18;

    // Calcula o total de minutos trabalhados
    const totalMinutes = hour * 60 + minutes;

    // Verifica se está dentro do horário normal de trabalho
    if ((hour >= startMorningHour && hour < endMorningHour) || (hour >= startAfternoonHour && hour < endAfternoonHour)) {
      return 'Normal';
    } else {
      return 'Extra';
    }
  };

  // Nova função para lidar com o clique na foto
  const handleFotoClick = (foto) => {
    setFotoSelecionada(foto); // Atualiza o estado com a foto clicada
    setVisualizarFoto(true); // Ativa a visualização da foto
  };

  // Função para fechar a visualização da foto
  const handleCloseVisualizacao = () => {
    setVisualizarFoto(false);
  };

  if (!funcionario) {
    return <div>Carregando...</div>;
  }

  return (
    <div className="bodyUse">
      <div className="cadastro">
        <div className="topo">
          <div className="barra" style={{display: 'flex', flexDirection: 'row'}}>
            <div className="titulo" style={{display: 'flex', flexDirection: 'row'}}>
              <div className="tituloRelat">Nome: {funcionario.funcionario.nome}</div>
              <div className="tituloRelat">CPF: {funcionario.funcionario.cpf}</div>
                <div className="tituloRelat">Nº Folha: {funcionario.funcionario.folha}</div>
            </div>
            <div className="adc-exc" style={{display: 'flex', flexDirection: 'row'}}>
                <button className="button-complet" onClick={handlePrint}>Imprimir</button>
            </div>
          </div>
          {visualizarFoto && (
                <div style={{
                  position: 'fixed', 
                  top: 0, 
                  left: 0, 
                  width: '100%', 
                  height: '100%', 
                  backgroundColor: 'rgba(0, 0, 0, 0.5)', 
                  display: 'flex', 
                  justifyContent: 'center', 
                  alignItems: 'center', 
                  zIndex: 1000
                }}>
                  <div style={{
                    position: 'relative', 
                    display: 'flex', 
                    justifyContent: 'center', 
                    alignItems: 'center',  
                    padding: '20px', 
                    borderRadius: '10px', 
                  }}>
                    <img src={`data:image/jpeg;base64,${fotoSelecionada}`} alt="Foto ampliada do usuário" style={{ 
                      maxWidth: '20%', // Torna a imagem menor
                      maxHeight: '50%', 
                      objectFit: 'cover' // Isso garante que a foto se ajuste bem dentro dessas dimensões, sem distorcer
                    }} />
                    <button className="button-complet" onClick={handleCloseVisualizacao} style={{ 
                      position: 'absolute', 
                      top: '10px', 
                      right: '10px', 
                      padding: '5px 10px', 
                      cursor: 'pointer' 
                    }}>
                      Fechar
                    </button>
                  </div>
                </div>
              )}
          <div className="barra-inferior" style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
            <div className="resultados">
              <div className="tituloRelat">
                Mostrar
                <span className="select">
                  <select name="opcao">
                    <option value="10">10</option>
                  </select>
                </span>
                resultados por página
              </div>
            </div>
            <div className="busca" style={{display: 'flex', flexDirection: 'row'}}>
              <div className="buscarNew">Buscar</div>
              <input type="search" value="" /><img src="search.png" alt="" width="20" />
            </div>
          </div>
        </div>
        {funcionario.usuarios && funcionario.usuarios.length > 0 && (
        <table className="table">
            <thead>
              <tr>
              <td><input type="checkbox" /></td>
                <th>Identificador</th>
                <th>Data e Hora</th>
                <th>Período</th>
                <th>Horas Extras</th>
                <th>Foto</th>
              </tr>
            </thead>
            <tbody>
              {funcionario.usuarios.map(usuario => (
                <tr key={usuario.id}>
                  <td><input type="checkbox" /></td>
                  <td>{usuario.identificador}</td>
                  <td>{getFormattedDate(usuario.timestamp)}</td>
                  <td>{getPeriod(usuario.timestamp)}</td>
                  <td>{calculateExtraHours(usuario.timestamp)}</td>
                  <td>
                  {usuario.foto && (
                    <img src={`data:image/jpeg;base64,${usuario.foto}`} alt="Foto do Usuário" style={{width: '50px', cursor: 'pointer'}} onClick={() => handleFotoClick(usuario.foto)} />
                  )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
      )}
        <tbody>
      </tbody>
        <div className="footer">
          <div className="informacao-paginacao">Mostrar 1 a 10 de 13 funcionários</div>
          <div className="paginacao"><button>1</button><button>2</button><button>Próxima</button></div>
        </div>
      </div>
    </div>
  );
}

export default FuncionarioDetalhes;
