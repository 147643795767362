import { Link } from 'react-router-dom';
import './usuarioRel.css';
import React, { useState, useEffect } from 'react';
import axios from 'axios';

const GoogleMapsLinks = () => {
    const [links, setLinks] = useState([]);
    const [selectedLink, setSelectedLink] = useState(null);

    useEffect(() => {
        const fetchLinks = async () => {
            try {
                const response = await axios.get('https://pontoweb.kiponto.com:8900/links-google-maps');
                setLinks(response.data);
            } catch (error) {
                console.error('Erro ao buscar links do Google Maps:', error.message);
            }
        };
        
        fetchLinks();
    }, []);

    const googleLinks = links.filter(link => link.includes('google')); // Filtra os links que contêm a palavra "google"

    const openMapInNewTab = (link) => {
        window.open(link, '_blank');
    };

    return (
    <div className="bodyUse">
      <div className="menu-lateral">
      <ul>
          <li><Link to="/Home"><img src="./voltar.png" alt="Voltar" /></Link></li>
          <li><Link to="/cartao-ponto">Cartão de Ponto</Link></li>
          <div className="separador"></div>
          <li><Link to="/Home">Solicitações Central do Funcionário</Link></li>
          <li><Link to="/Home">Assinatura Eletrônica de Cartão Ponto</Link></li>
          <li><Link to="/Home">Encerramento de Cálculos</Link></li>
          <li><Link to="/Home">Importar Arquivo AFD</Link></li>
          <li><Link to="/Home">Lançamento de Eventos</Link></li>
          <li><Link to="/Home">Lançamento de Atividades</Link></li>
          <li><Link to="/Home">Gestão de Arquivo</Link></li>
          <div className="separador"></div>
          <li><Link to="/Home">Comunicação</Link></li>
          <li><Link to="/localizacao" style={{ fontWeight: 'bold' }}>Log de Localização</Link></li>
        </ul>
      </div>
      <div className="cadastro">
        <div className="topo">
          <div className="barra" style={{display: 'flex', flexDirection: 'row'}}>
            <div className="titulo" style={{display: 'flex', flexDirection: 'row'}}>
              <img src="fun.png" alt="" width="50" />
              <div className="tituloRelat">LOCALIZAÇÃO</div>
            </div>
            <div className="adc-exc" style={{display: 'flex', flexDirection: 'row'}}>
              <a href="/cadastro-funcionario"><img src="plus.png" alt="" style={{marginRight: '15px'}} /></a>
              <img src="trash.png" alt="" />
            </div>
          </div>
          <div className="barra-inferior" style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
            <div className="resultados">
              <div className="tituloRelat">
                Mostrar
                <span className="select">
                  <select name="opcao">
                    <option value="10">10</option>
                  </select>
                </span>
                resultados por página
              </div>
            </div>
            <div className="busca" style={{display: 'flex', flexDirection: 'row'}}>
              <div className="buscarNew">Buscar</div>
              <input type="search" value="" /><img src="search.png" alt="" width="20" />
            </div>
          </div>
        </div>
        <table className="table">
          <thead>
            <tr>
              <th><input type="checkbox" /></th>
              <th>MAPA</th>
              <th>LATITUDE</th>
              <th>LONGITUDE</th>
            </tr>
          </thead>
          <tbody>
            {googleLinks.map((link, index) => (
              <tr key={index}>
                <td><input type="checkbox" /></td>
                <td><a href={link} style={{ color: '#000' }} target="_blank">Clique para ver o mapa</a></td>
                <td></td>
                <td></td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="footer">
          <div className="informacao-paginacao">Mostrar 1 a 10 de 13 funcionários</div>
          <div className="paginacao"><button>1</button><button>2</button><button>Próxima</button></div>
        </div>
      </div>
    </div>
  );
}
const RelatorioInit = () => {

    return (
        <div>
            <GoogleMapsLinks />
        </div>
    );
};
export default GoogleMapsLinks;
