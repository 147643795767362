import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './usuarioRel.css';
import { Link } from 'react-router-dom';

const EmpresaInit = () => {
  const [funcionarios, setFuncionarios] = useState([]);

  useEffect(() => {
    const fetchFuncionarios = async () => {
      try {
        const funcionariosResponse = await axios.get('https://pontoweb.kiponto.com:8900/dados-funcionarios');
        const funcionariosData = funcionariosResponse.data;
        
        const pontosResponse = await axios.get('https://pontoweb.kiponto.com:8900/dados-usuarios');
        const pontosData = pontosResponse.data;

        const funcionariosComPontos = funcionariosData.map(funcionario => {
          const pontosFuncionario = pontosData.filter(ponto => ponto.identificador === funcionario.identificador);
          const pontosMarcados = pontosFuncionario.map(ponto => ({
            id: ponto.id,
            hora: formatarHora(new Date(ponto.timestamp)),
            marcacao: verificarMarcacao(new Date(ponto.timestamp))
          }));
          return {
            id: funcionario.id,
            nome: funcionario.nome,
            pontos: pontosMarcados,
            tol_extra: calcularHorasExtras(pontosMarcados),
            tol_falta: calcularHorasFalta(pontosMarcados)
          };
        });

        setFuncionarios(funcionariosComPontos);
      } catch (error) {
        console.error('Erro ao buscar dados dos funcionários:', error.message);
      }
    };

    fetchFuncionarios();
  }, []);

  // Função para formatar a hora para o formato de 24 horas
  const formatarHora = (hora) => {
    const horas = hora.getHours().toString().padStart(2, '0');
    const minutos = hora.getMinutes().toString().padStart(2, '0');
    return `${horas}:${minutos}`;
  };

  // Função para verificar se a marcação está dentro do horário normal
  const verificarMarcacao = (horaMarcada) => {
    const horas = horaMarcada.getHours();
    const minutos = horaMarcada.getMinutes();
    
    // Comparar com os horários da tabela
    if ((horas >= 7 && horas <= 12 && minutos <= 59) || (horas >= 13 && horas <= 17 && minutos <= 59)) {
      return 'Normal';
    } else {
      // Calcular horas extras
      const horaLimite = new Date(horaMarcada);
      horaLimite.setHours(horas < 13 ? 12 : 17, 0, 0, 0); // Define a hora limite como 12:00 ou 17:00
      const diff = horaMarcada - horaLimite; // Diferença em milissegundos
      const horasExtras = Math.max(0, Math.floor(diff / (1000 * 60 * 60))); // Horas extras (no mínimo zero)
      return `${horasExtras}h`;
    }
  };

  // Função para calcular as horas extras totais de um funcionário
  const calcularHorasExtras = (pontos) => {
    let horasExtras = 0;
    pontos.forEach(ponto => {
      const horas = parseInt(ponto.hora.split(':')[0]);
      const marcacao = verificarMarcacao(new Date(`2000-01-01T${ponto.hora}`));
      if (marcacao !== 'Normal') {
        horasExtras += horas;
      }
    });
    return `${horasExtras}h`;
  };

  // Função para calcular as horas de falta totais de um funcionário
  const calcularHorasFalta = (pontos) => {
    const diasTrabalhados = pontos.filter(ponto => ponto.marcacao === 'Normal').length;
    const horasFalta = (5 - diasTrabalhados) * 8; // 5 dias úteis * 8 horas por dia
    return `${horasFalta}h`;
  };

  // Função para imprimir o cartão de ponto
  const imprimirCartaoPonto = () => {
    window.print();
  };

  // Função para enviar o relatório por email
  const enviarPorEmail = () => {
    const subject = 'Relatório de Cartão de Ponto';
    const body = formatarEmail();
    const mailtoLink = `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

    window.location.href = mailtoLink;
  };

  // Função para formatar os dados do relatório em um formato de email
  const formatarEmail = () => {
    let emailBody = 'Relatório de Cartão de Ponto:\n\n';
    funcionarios.forEach(funcionario => {
      emailBody += `Nome: ${funcionario.nome}\n`;
      emailBody += `Entrada 1: ${funcionario.pontos[0] ? funcionario.pontos[0].hora : '-'}\n`;
      emailBody += `Saída 1: ${funcionario.pontos[1] ? funcionario.pontos[1].hora : '-'}\n`;
      emailBody += `Entrada 2: ${funcionario.pontos[2] ? funcionario.pontos[2].hora : '-'}\n`;
      emailBody += `Saída 2: ${funcionario.pontos[3] ? funcionario.pontos[3].hora : '-'}\n`;
      emailBody += `Entrada 3: ${funcionario.pontos[4] ? funcionario.pontos[4].hora : '-'}\n`;
      emailBody += `Saída 3: ${funcionario.pontos[5] ? funcionario.pontos[5].hora : '-'}\n`;
      emailBody += `Normais: ${funcionario.tol_extra}\n`;
      emailBody += `Extra: ${funcionario.tol_extra}\n`;
      emailBody += `Falta: ${funcionario.tol_falta}\n\n`;
    });
    return emailBody;
  };

  return (
    <div className="bodyUse">
      <div className="menu-lateral imprimir-nao">
        <ul>
          <li><Link to="/Home"><img src="./voltar.png" alt="Voltar" /></Link></li>
          <li><Link to="/cartao-ponto" style={{ fontWeight: 'bold' }}>Cartão de Ponto</Link></li>
          <div className="separador"></div>
          <li><Link to="/Home">Solicitações Central do Funcionário</Link></li>
          <li><Link to="/Home">Assinatura Eletrônica de Cartão Ponto</Link></li>
          <li><Link to="/Home">Encerramento de Cálculos</Link></li>
          <li><Link to="/Home">Importar Arquivo AFD</Link></li>
          <li><Link to="/Home">Lançamento de Eventos</Link></li>
          <li><Link to="/Home">Lançamento de Atividades</Link></li>
          <li><Link to="/Home">Gestão de Arquivo</Link></li>
          <div className="separador"></div>
          <li><Link to="/Home">Comunicação</Link></li>
          <li><Link to="/localizacao">Log de Localização</Link></li>
        </ul>
      </div>
      <div className="cadastro">
        <div className="topo">
          <div className="barra" style={{display: 'flex', flexDirection: 'row'}}>
            <div className="titulo" style={{display: 'flex', flexDirection: 'row'}}>
              <img src="fun.png" alt="" width="50" />
              <div className="tituloRelat">CARTÃO DE PONTO</div>
            </div>
            <div className="adc-exc" style={{display: 'flex', flexDirection: 'row'}}>
              <img src="email.png" alt=""  onClick={enviarPorEmail}  style={{marginRight: '15px'}} />
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <img src="imprimir.png" alt="" onClick={imprimirCartaoPonto} />
              </div>
            </div>
          </div>
          <div className="barra-inferior imprimir-nao" style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
            <div className="resultados">
              <div className="tituloRelat">
                Mostrar
                <span className="select">
                  <select name="opcao">
                    <option value="10">10</option>
                  </select>
                </span>
                resultados por página
              </div>
            </div>
            <div className="busca" style={{display: 'flex', flexDirection: 'row'}}>
              <div className="buscarNew">Buscar</div>
              <input type="search" value="" /><img src="search.png" alt="" width="20" />
            </div>
          </div>
        </div>
        <table className="table">
          <thead>
            <tr>
              <th>Nome</th>
              <th>Entrada 1</th>
              <th>Saída 1</th>
              <th>Entrada 2</th>
              <th>Saída 2</th>
              <th>Entrada 3</th>
              <th>Saída 3</th>
              <th>Normais</th>
              <th>Extra</th>
              <th>Falta</th>
            </tr>
          </thead>
          <tbody>
            {funcionarios.map(funcionario => (
              <tr key={funcionario.id}>
                <td>{funcionario.nome}</td>
                <td>{funcionario.pontos[0] ? funcionario.pontos[0].hora : '-'}</td>
                <td>{funcionario.pontos[1] ? funcionario.pontos[1].hora : '-'}</td>
                <td>{funcionario.pontos[2] ? funcionario.pontos[2].hora : '-'}</td>
                <td>{funcionario.pontos[3] ? funcionario.pontos[3].hora : '-'}</td>
                <td>{funcionario.pontos[4] ? funcionario.pontos[4].hora : '-'}</td>
                <td>{funcionario.pontos[5] ? funcionario.pontos[5].hora : '-'}</td>
                <td>
                  <ul>
                    {funcionario.pontos.map(ponto => (
                      <div key={ponto.id}>{ponto.marcacao}</div>
                    ))}
                  </ul>
                </td>
                <td>{funcionario.tol_extra}</td>
                <td>{funcionario.tol_falta}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="footer imprimir-nao">
          <div className="informacao-paginacao">Mostrar 1 a 10 de 13 funcionários</div>
          <div className="paginacao"><button>1</button><button>2</button><button>Próxima</button></div>
        </div>
      </div>
    </div>
  );
}

export default EmpresaInit;
