import React from 'react';
import './usuarioRel.css';

function EmpresaInit() {
  return (

            <div>
              <img src="PG.png" alt="" width="100%" />
            </div>
  
  );
}

export default EmpresaInit;
