import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import './usuarioRel.css';


function EmpresaInit() {
  const [empresas, setEmpresas] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://pontoweb.kiponto.com:8900/dados-empresas');
        setEmpresas(response.data);
      } catch (error) {
        console.error('Erro ao buscar dados das empresas:', error.message);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="bodyUse">
      <div className="menu-lateral">
      <ul>
          <li><Link to="/Home"><img src="./voltar.png" alt="Voltar" /></Link></li>
          <li><Link to="/empresa" style={{ fontWeight: 'bold' }}>Empresas</Link></li>
          <li><Link to="/horario">Horários</Link></li>
          <li><Link to="/relatorio-funcionario">Funcionários</Link></li>
          <div className="separador"></div>
          <li><Link to="/banco-de-horas">Banco de Horas</Link></li>
          <div className="separador"></div>
          <li><Link to="/eventos">Eventos</Link></li>
          <li><Link to="/atividades">Atividades</Link></li>
          <li><Link to="/funcao">Funções</Link></li>
          <li><Link to="/departamentos">Departamentos</Link></li>
          <li><Link to="/estruturas">Estruturas</Link></li>
          <li><Link to="/centro-de-custos">Centro de Custos</Link></li>
          <li><Link to="/motivos-de-demissoes">Motivos de Demissões</Link></li>
          <li><Link to="/escolaridades">Escolaridades</Link></li>
          <div className="separador"></div>
          <li><Link to="/feriados">Feriados</Link></li>
          <li><Link to="/cidades">Cidades</Link></li>
        </ul>
      </div>
      <div className="cadastro">
        <div className="topo">
          <div className="barra" style={{display: 'flex', flexDirection: 'row'}}>
            <div className="titulo" style={{display: 'flex', flexDirection: 'row'}}>
              <img src="fun.png" alt="" width="50" />
              <div className="tituloRelat">EMPRESA</div>
            </div>
            <div className="adc-exc" style={{display: 'flex', flexDirection: 'row'}}>
              <a href="/cadastro-empresas"><img src="plus.png" alt="" style={{marginRight: '15px'}} /></a>
              <img src="trash.png" alt="" />
            </div>
          </div>
          <div className="barra-inferior" style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
            <div className="resultados">
              <div className="tituloRelat">
                Mostrar
                <span className="select">
                  <select name="opcao">
                    <option value="10">10</option>
                  </select>
                </span>
                resultados por página
              </div>
            </div>
            <div className="busca" style={{display: 'flex', flexDirection: 'row'}}>
              <div className="buscarNew">Buscar</div>
              <input type="search" value="" /><img src="search.png" alt="" width="20" />
            </div>
          </div>
        </div>
        <table className="table">
          <thead>
            <tr>
              <th><input type="checkbox" /></th>
              <th>Nome</th>
              <th>CNPJ</th>
              <th>Inscrição Estadual</th>
            </tr>
          </thead>
          <tbody>
          {empresas.map((empresa, index) => (
            <tr key={index}>
              <td><input type="checkbox" /></td>
              <td>{empresa.nome}</td>
              <td>{empresa.cnpj}</td>
              <td>{empresa.inscricao}</td>
            </tr>
          ))}
        </tbody>
        </table>
        <div className="footer">
          <div className="informacao-paginacao">Mostrar 1 a 10 de 13 funcionários</div>
          <div className="paginacao"><button>1</button><button>2</button><button>Próxima</button></div>
        </div>
      </div>
    </div>
  );
}

export default EmpresaInit;
