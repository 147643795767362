import React from 'react';
import { createRoot } from 'react-dom/client'; // Importe createRoot corretamente
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './index.css';

// Importação dos componentes
import { AuthProvider } from './AuthContext';
import LoginForm from './LoginForm';
import HomeInit from './HomeInit';
import Logout from './Logout';
import CadastroUsuario from './CadastroUsuario';
import CadastroEmpresas from './CadastroEmpresas';
import MovimentacaoInit from './MovimentacaoInit';
import ManutencaoInit from './ManutencaoInit';
import RelatorioInit from './RelatorioInit';
import FuncionarioDetalhes from './FuncionarioDetalhes';
import EmpresaInit from './EmpresaInit';
import HorarioInit from './HorarioInit';
import CadastroHorario from './CadastroHorario';
import FuncaoInit from './FuncaoInit';
import CadastroFuncao from './CadastroFuncao';
import DepartamentoInit from './DepartamentoInit';
import CadastroDepartamento from './CadastroDepartamento';
import EstruturaInit from './EstruturaInit';
import CadastrarEstrutura from './CadastroEstrutura';
import DemissaoInit from './DemissaoInit';
import CadastroDemissao from './CadastroDemissao';
import EscolaridadeInit from './EscolaridadeInit';
import CadastrarEscolaridade from './CadastrarEscolaridade';
import FeriadoInit from './FeriadoInit';
import CadastrarFeriado from './CadastrarFeriado';
import Localizacao from './Localizacao';


import PaginaConstrucao from './PaginaConstrucao';


import CartaoPonto from './CartaoPonto';

// Componente principal do aplicativo
const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LoginForm />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/Home" element={<HomeInit />} />
        <Route path="/cadastro-funcionario" element={<CadastroUsuario />} />
        <Route path="/cadastro-empresas" element={<CadastroEmpresas />} />
        <Route path="/cadastro-horarios" element={<CadastroHorario />} />
        <Route path="/movimentacao" element={<MovimentacaoInit />} />
        <Route path="/manutencao" element={<ManutencaoInit />} />
        <Route path="/relatorio-funcionario" element={<RelatorioInit />} />
        <Route path="/empresa" element={<EmpresaInit />} />
        <Route path="/horario" element={<HorarioInit />} />
        <Route path="/funcao" element={<FuncaoInit />} />
        <Route path="/cadastro-funcao" element={<CadastroFuncao />} />
        <Route path="/departamentos" element={<DepartamentoInit />} />
        <Route path="/cadastro-departamento" element={<CadastroDepartamento />} />
        <Route path="/estruturas" element={<EstruturaInit />} />
        <Route path="/cadastrar-estrutura" element={<CadastrarEstrutura />} />
        <Route path="/motivos-de-demissoes" element={<DemissaoInit />} />
        <Route path="/cadastro-demissoes" element={<CadastroDemissao />} />
        <Route path="/escolaridades" element={<EscolaridadeInit />} />
        <Route path="/cadastrar-escolaridade" element={<CadastrarEscolaridade />} />
        <Route path="/feriados" element={<FeriadoInit />} />
        <Route path="/cadastrar-feriado" element={<CadastrarFeriado />} />
        <Route path="/localizacao" element={<Localizacao />} />
        <Route path="/cartao-ponto" element={<CartaoPonto />} />
        <Route path="/funcionarios/:id" element={<FuncionarioDetalhes />} />


        <Route path="/eventos" element={<PaginaConstrucao/>}/>
        <Route path="/atividades" element={<PaginaConstrucao/>}/>
        <Route path="/centro-de-custos" element={<PaginaConstrucao/>}/>
        <Route path="/cidades" element={<PaginaConstrucao/>}/>
        <Route path="/banco-de-horas" element={<PaginaConstrucao/>}/>
      </Routes>
    </Router>
  );
};

// Renderização do aplicativo, envolvendo-o com o AuthProvider
createRoot(document.getElementById('root')).render(
  <AuthProvider>
    <App />
  </AuthProvider>
);
